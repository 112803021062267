// extracted by mini-css-extract-plugin
export var fits10 = "what-fits-you-module--fits-10--hxTsI";
export var fits10Right = "what-fits-you-module--fits-10-right--dmXju";
export var fits11 = "what-fits-you-module--fits-11--sAHgd";
export var fits11Right = "what-fits-you-module--fits-11-right--2dli9";
export var fits12 = "what-fits-you-module--fits-12--M6MOb";
export var fits2 = "what-fits-you-module--fits-2--NbFa5";
export var fits2Right = "what-fits-you-module--fits-2-right--UQ07g";
export var fits3 = "what-fits-you-module--fits-3--iiZMf";
export var fits3Right = "what-fits-you-module--fits-3-right--IcNHd";
export var fits4 = "what-fits-you-module--fits-4--joj+V";
export var fits4Right = "what-fits-you-module--fits-4-right--PBpmZ";
export var fits5 = "what-fits-you-module--fits-5--7MCq-";
export var fits5Right = "what-fits-you-module--fits-5-right--6h-p0";
export var fits6 = "what-fits-you-module--fits-6--cZmnC";
export var fits6Right = "what-fits-you-module--fits-6-right--CE4g2";
export var fits7 = "what-fits-you-module--fits-7--Bpo-N";
export var fits7Right = "what-fits-you-module--fits-7-right--SIaSb";
export var fits8 = "what-fits-you-module--fits-8--SL-VQ";
export var fits8Right = "what-fits-you-module--fits-8-right--vVl1a";
export var fits9 = "what-fits-you-module--fits-9--SrltE";
export var fits9Right = "what-fits-you-module--fits-9-right--H4vCJ";
export var fitsAbout = "what-fits-you-module--fits-about--LofJd";
export var fitsAboutSubtext = "what-fits-you-module--fitsAboutSubtext--+lBQh";
export var fitsAboutText = "what-fits-you-module--fits-about-text--07Amb";
export var fitsHeading = "what-fits-you-module--fits-heading--r-SVN";
export var fitsImg = "what-fits-you-module--fits-img--pc96P";
export var fitsIntro = "what-fits-you-module--fits-intro--IRq0O";
export var fitsPage = "what-fits-you-module--fits-page--6RGBu";
export var fitsQuotation = "what-fits-you-module--fits-quotation--U16fA";
export var icon = "what-fits-you-module--icon--vou7-";
export var mainSection = "what-fits-you-module--main-section--MOnry";
export var quoteSignature = "what-fits-you-module--quote-signature--U87il";
export var quoteText = "what-fits-you-module--quote-text--DtMSp";
export var ratio = "what-fits-you-module--ratio--JpIzZ";
export var shapeImg = "what-fits-you-module--shape-img--p8DeW";
export var typeFigure = "what-fits-you-module--type-figure--n7S-r";
export var typeFigureRight = "what-fits-you-module--type-figure-right--DeWdp";